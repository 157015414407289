@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

@media (min-width: 1200px) {

  .mx-50::after,
  .me-50::after {
    position: absolute;
    content: "";
    right: -50px;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
    height: 53px;
    background-color: #cccccc;
  }
}

@media (min-width: 1400px) {}

@media (max-width: 1549.98px) {
  .icon-suffix{
    margin-left: 8px;
  }
  .error{
    font-size: 14px;
  }
  .carousel.slide{
    max-width: 450px;
  }
  .healthcareImg{
    width: 120px;
  }
  .fw-36 {
    font-size: 30px;
  }

  .line::before {
    height: 18px;
    /* top: 47px; */
    left: 47px;
  }
  .emailicon {
    /* top: 45px; */
    left: 13px;
    width: 22px;
  }
  .login-form input {
    padding: 12px 13px;
    font-size: 14px;
  }
  .formpadding input {
    padding: 12px 12px 12px 58px;
  }
  .login-padding .py3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .editbuttom,.anskey, .addcreatebtn{
    padding: 10px 20px;
  }
  .addcreatebtn{
    background-color: var(--hover-color);
    color: white;
    font-size: 14px;
  }
  .ant-modal-content{
    max-width: 450px;
    margin: 0 auto;
    padding: 20px !important;
  }
  .switch div,.switches-container .mempro{
    font-size: 14px;
  }
  .switches-container .mempro{
    padding: 5px  ;
  }
  .switches-container{
    width: 195px !important;
  }
.addcreatebtn span,.savebtn.fw-18 ,.okbtn, .okbtn:hover, .okbtn:active{
  font-size: 14px !important;
}
.ant-modal .ant-modal-close-x{
  font-size: 14px;
  line-height: 20px;
}
.ant-modal .ant-modal-close{
  height: 24px;
  width: 24px;
}

.okbtn, .okbtn:hover, .okbtn:active{
  padding: 11px 30px !important;
}.me-20 {
    margin-right: 10px;
}
  .groupgrid {
    gap: 20px;
  }

  /* .emailicon {
    top: 48px;
  } */

  .responsiveflex .verticalline {
    display: none;
  }

  .responsiveflex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .minwidthfix{
    /* border-right: 1px solid #473f4451; */
    margin-right: 30px ;
    padding-right: 30px;
  }
  .minwidthfix img{
    height: 20px;
    width: 20px;
  }
  .mb-10{
    margin-bottom: 7px;
  }
  .minwidthfix:nth-child(2n){
    border-right: none !important;margin-right: 30px;
  }
  .head-text img {
    width: 80% !important;
    margin: 0 auto;
  }

  .box-border label img {
    width: 80%;
    padding: 40px 0;
  }

  .fw-36 {
    font-size: 30px;
  }

  .fw-18,
  .login-form label {
    font-size: 16px !important;
  }

  .userintial {
    font-size: 18px;
  }

  .mb-40 {
    margin-bottom: 25px;
  }

  .heedprodct {
    font-size: 18px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 0 !important;
  }
  .accordion-item{
    margin-bottom: 20px;
  }
  .form-check{
    margin-bottom: 5px;
  }
  .accordion-button{
    padding: 20px !important;
    font-size: 16px !important;
  }
  .me-30 {
    margin-right: 16px;
  }
  td.ant-table-cell ,.ant-table-cell{
    font-size: 14px !important;
  }
  .manage {
    width: 65px;
  }
  
  
  .downloadReports {
    width: 80px;
    font-size: 12px;
  }
  .mb-60 {
    margin-bottom: 40px;
  }

  .headdetail{
    padding: 8px 16px;
    font-size: 12px;
  }
  .my-20 {
    margin: 14px 0;
  }
  .producttitle{
    font-size: 16px;
  }
  .producttitle label{
    font-size: 14px;
  }
  .groupcheck{
    height: 34px;
  }
  .mx-35 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .d-4grid .verticalline{
    height: 50px;
  }
  .rightcontent,
  .optionflex,
  .sectionpadding,
  .headtitle,
  .tab-content,
  .p-30,
  .table-responsive,
  .usernamedetail,
  .userotherdetail {
    padding: 18px 22px;
  }
  .ant-switch{
    transform: scale(0.9);
  }
  .ant-switch.me-3{
    margin-right: 12px !important

  }
  
  .table-responsive {
    padding: 10px 15px;
  }
  .ant-table-cell{
    padding: 12px !important;
  }
  th {
    padding: 12px !important;
    min-width: 200px;
  }

  .inactive,
  .failed,
  .activted,
  .sent,
  .schedule,
  .manage,
  .userid {
    padding: 7px 8px;
    font-size: 12px;
  }
.ant-table-column-title,.ant-pagination .ant-pagination-options::before, .ant-pagination-total-text,.ant-select-selection-item{
  font-size: 14px;
}
.ant-pagination .ant-select .ant-select-arrow::after {
  border: 5px solid var(--secondary-text);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.ant-select-selection-item{
  padding-right: 12px !important;
  margin-right: 12px !important;
}
.ant-select-selector{
  padding: 0 8px !important;
}
.anticon svg{
  height: 14px;
  width: 14px;
}
.ant-pagination .ant-pagination-prev{
  margin: 0 !important;
}
  .usernamedetail .userimg {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }

  .mb-20,
  .mb-25 {
    margin-bottom: 16px;
  }

  .username {
    margin-right: 30px;
  }

  .primarybtn {
    padding: 10px 15px;
  }
  
  .plus{
    height: 22px;
    width: 22px;
  }
  button{
    font-size: 14px;
  }
  .mb-30 {
    margin-bottom: 20px;
  }

  .sidebar {
    padding: 0 8px;
  }
  .sidebar-content li{
    padding: 10px;
    padding-left: 15px;
  }
  .hoverimges,.logout img{
    margin-right: 8px;
    height: 22px;
    width: 22px;
  }
  p {
    margin-bottom: 12px;
  }
  .ant-modal-close svg{
    height: 10px;
    width: 10px;
  }
  .breadcrumb-item+.breadcrumb-item::before{
    margin-top: 1px;
  }
  .breadcrumb-item,.breadcrumb-item a{
    font-size: 14px;
  }
  .logout span{
    font-size: 14px;
  }
  .item-content{
    font-size: 14px;
  }
  .sidebar-content li.active::after {
    right: calc(-100% - 12px);
  }

  .hoverimges img {
    height: 22px;
    width: 22px;
    margin-top: -8px;
  }
  .table-search-input {
    height: 44px;
}
.ant-input{
  font-size: 14px !important;
}
  .ant-input-prefix img{
    width: 20px;
    height: 20px;
  }
  .plus:before {
    top: 4px;
    /* this defines how much black "border" there should be */
    bottom: 4px;
  }

  .plus::after {
    left: 3px;
    right: 3px;
  }

  .lineafter {
    padding-right: 30px;
    margin-right: 30px;
  }

  .grid2 {
    gap: 20px;
  }

  .tabbing ul {
    padding: 18px 20px 0 20px;
  }
  
  .nav-link{
    font-size: 14px !important;
  }

  .savebtn {
    padding: 10px 25px;
  }
  .ant-upload {
    min-height: 250px;
} 
.ant-upload-drag-icon img{
  height: 22px;
  width: 22px;
}
.ant-upload-text, .ant-upload-text u{
  font-size: 16px !important;
}.mt-3 {
  margin-top: 12px !important;
}
.ant-upload-list-item-container{
  padding: 16px;
}
  .optionselection .ant-checkbox+span {
    padding: 10.5px;
  }


  .table th,
  .table td {
    padding: 16px !important;
  }

  .cancelbtn.py3 {
    padding-top: 11.3px !important;
    padding-bottom: 11.3px !important;
  }

  body {
    font-size: 16px;
  }

  .sidebar {
    width: 230px;
    min-width: 230px;
  }

  .btn-toggle:before,
  .btn-toggle:after {
    font-size: 12px;
  }

  .btn-toggle:after {
    right: -75px;
  }
  .seprator {
    margin: 20px 0px;
  }
  .selecttype{
    min-width: 300px;
  }
  .btn-toggle::before {
    left: -90px;
  }

  .rightside {
    width: calc(100% - 230px);
  }

  .noicon .ant-picker {
    padding: 12px;
  }

  .ant-select-selector input,
  .ant-select-selector {
    height: 48px !important;
  }

 
  .state-wise .ant-select-selector{
    padding: 5px !important;
  }
  .ant-select {
    border: 0;
    min-height: 49px;
}
.hunset .ant-upload-list-item-container{
  padding: 10px;
}
  .allstate .ant-upload-text,
  .allstate .ant-upload-text u {
    font-size: 13px !important;
  }

  .certifiupload .allstate .ant-upload-drag-icon {
    margin-right: 10px;
  }

  .certifiupload .allstate .ant-upload-drag-icon,
  .certifiupload .allstate .ant-upload-drag-icon img {
    height: 18px;
    width: 18px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .imagerespo img {
    width: 24px;
  }

  .ant-upload-list-item-container img {
    width: 28px;
    height: 28px;
  }

  .sectionpadding .ant-select-arrow {
    transform: translateY(-30%) !important;
  }
  .file-icon{
    font-size: 14px;
  }

  .okbtn,
  .okbtn:hover,
  .okbtn:active {
    padding: 13px 32px;
  }

  .productname {
    font-size: 14px;
  }

  .disclaimerBtn {
    bottom: -64px;
  }

  .btnPosition {
    margin-bottom: 64px !important;
  }
  .addBtnQuestion {
    position: absolute;
    top: -96px;
    right: 20px;
}
.notchange{
  min-height: 34px;
}
.mx-100{
  margin-left: 40px;
  margin-right: 40px;
}
.addcreatebtn img{
  filter: brightness(0) invert(1);
  height: 24px;
}
.character-count{
  font-size: 14px;
}
.downloadReports{
  padding: 7px;
  font-size: 12px;
}
.primecare .cursorPointer {
  position: relative;
  height: 30px;
  width: 30px;
}
.sidebar.toggled {
  padding-left: 8px;
  padding-right: 8px;
}
.toggled+.rightside{
  width: calc(100% - 100px);
}
.toggled {
  width: 100px !important;
  min-width: 100px !important;
}
.ql-editor,
.removehtml {
  min-height: 250px;
}
.removehtml{
  font-size: 14px;
}

}
.responsiveflex {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  align-items: center;
}
@media (max-width: 1499.98px) {
  .line60 .verticalline {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (max-width: 1199.98px) {


  .me-50 {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .justifying {
    justify-content: space-between;
  }

  .justifying div {
    width: 50%;
  }

  .mx-50 {
    margin: 0;
  }

  .justifying .productname {
    width: 100%;
  }

  .ms-50 {
    margin-left: 00px;
  }
}

@media (max-width: 1099.98px) {
  .d-4grid .verticalline {
    display: none;
  }
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  
  .groupgrid {
    display: block;
  }
  .groupgrid>div {
    margin-bottom: 15px;
}
}

@media (max-width: 1023.98px) {
  .w-x-50 {
    width: 100%;
    height: 50%;
    min-height: 300px;
  }

  .dflex {
    display: block;
  }

  .carousel.slide {
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .hv-100 {
    height: calc(100vh - 30px);
  }

  .carousel-health .carousel-inner {
    border-radius: 0;
  }

  .login-padding {
    padding: 0 !important;
  }

  .carousel-health .carousel-inner {
    height: 100%;
  }

  .tcenter {
    text-align: center;
  }

  /* .login-padding .py3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  } */

  .box-border label img {
    padding: 40px 0;
  }

}

@media (max-width: 991.98px) {
  .disclaimerBtn {
    bottom: -54px;
  }

  .btnPosition {
    margin-bottom: 54px !important;
  }

  .mb-10 {
    margin-bottom: 4px;
  }

  .editbuttom {
    padding: 10px 20px;
  }

  .overlayinput {
    padding-right: 10px;
  }

  .fw-36 {
    font-size: 24px;
  }

  .mb-40:not(.login-padding .mb-40) {
    margin-bottom: 16px;
  }
/* 
  .emailicon {
    top: 42px;
  } */

  .productname {
    font-size: 12px;
  }

  .ant-switch {
    transform: scale(0.8);
  }

  .mb-60:not(.login-padding .mb-60) {
    margin-bottom: 24px;
  }
  .state-wise .ant-select-selector {
    height: 48px !important;
}
  .rightcontent,
  .optionflex,
  .sectionpadding,
  .headtitle,
  .tab-content,
  .p-30,
  .table-responsive,
  .usernamedetail,
  .userotherdetail {
    padding: 16px;
  }

  .inactive,
  .failed,
  .activted,
  .sent,
  .schedule,
  .manage,
  .userid,
  * {
    font-size: 12px;
  }

  .inactive,
  .failed,
  .activted,
  .sent,
  .schedule,
  .manage,
  .userid {
    padding: 5px 8px;
  }

  .d-flex-wrap {
    flex-wrap: wrap;
    display: flex;
  }

  .orderlg {
    order: 2;
  }
  .hunset{
    margin-bottom: 16px;
  }
  .hoverimges img {
    height: 16px;
    width: 16px;
  }

  .head-text img {
    width: 70% !important;
    margin: 0 auto;
  }

  .head-div {
    padding: 20px 0;
  }

  .icon-suffix img {
    width: 18px;
  }

  .btn-toggle:before,
  .btn-toggle:after {
    bottom: -2px;
  }

  .ant-table-cell {
    padding: 10px !important;
  }

  .d-4grid .verticalline {
    display: none;
  }
  .addBtnQuestion {
    position: absolute;
    top: -73px;
    right: 16px;
}
  .optionselected {
    font-size: 14px;
  }

  .selecttype {
    min-width: unset;
    width: 100%;
  }

  .addclaimbtn {
    padding: 10px;
    margin-bottom: 10px;
    color: var(--white) !important;
    background-color: var(--active);
  }

  .addcreatebtn {
    padding: 10px;
    color: var(--white) !important;
    background-color: var(--hover-color);
  }

  .optionselection input[type="checkbox"]:checked+label input[type="text"] {
    width: 70%;
  }

  .optionselected {
    margin: 0;
  }

  .addclaimbtn img {
    filter: brightness(0) invert(1);
  }

  .addcreatebtn img {
    filter: brightness(0) invert(1);
    margin-right: 4px;
  }

  .detailtitle {
    font-size: 12px;
  }

  .accordion-button,
  .usernamedetail,
  .userotherdetail {
    padding: 20px;
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 20px 0px;
  }

  .username {
    margin-right: 20px;
  }

  .usernamedetail .userimg {
    height: 40px;
    width: 40px;
  }

  .cancelbtn,
  .cancelbtn:hover,
  .cancelbtn:active,
  .okbtn,
  .okbtn:hover,
  .okbtn:active,
  .savebtn {
    padding: 10px 20px;
  }

  .d-4grid>div {
    width: 100%;
  }

  .lineafter {
    padding-right: 16px;
    margin-right: 16px;
  }

  .login-form input,
  .noicon .ant-picker {
    padding: 10px 15px;
  }

  .formpadding input {
    padding: 15px 15px 15px 61px;
  }


  .d-4grid .text-center {
    text-align: left !important;
  }

  .table th,
  .table td {
    padding: 15px !important;
  }

  .ant-select-selector input,
  .ant-select-selector {
    height: 42px !important;
  }

  .d-4grid {
    /* display: grid !important; */
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 16px;
  }

  .headdetail {
    font-size: 14px;
    padding: 8px 24px;
  }

  .ant-upload-drag-icon {
    margin-bottom: 5px !important;
  }

  .ant-upload-drag-icon img {
    height: 20px;
    width: 20px;
  }

  .ck-content {
    min-height: 200px;
  }

  .w-md-50 {
    width: 100%;
  }

  .table td {
    font-size: 14px;
  }


  .groupgrid>div {
    margin-bottom: 15px;
  }

  .ant-checkbox-input,
  .ant-checkbox,
  .ant-checkbox-inner {
    height: 16px !important;
    width: 16px !important;
  }

  .my-20 {
    margin: 10px 0;
  }

  .noicon {
    width: 100%;
  }

 

  .fs-16 {
    font-size: 12px;
  }

  .mb-30 {
    margin-bottom: 16px;
  }

  .tabbing ul {
    padding: 16px 16px 0 16px;
  }

  .nav-link:focus,
  .nav-link:hover,
  .nav-link {
    font-size: 12px !important;
  }

  .ant-upload-text,
  .ant-upload-text u {
    font-size: 12px !important;
  }

 
  .upload-area {
    min-height: 150px;
    padding: 15px;
  }

  .optionflex button {
    margin-bottom: 5px;
    margin-right: 0 !important;
  }

  .optionflex div:last-child {
    text-align: center;
  }

  * {
    font-size: 14px;
  }

  .breadcrumb-item a,
  .breadcrumb-item {
    font-size: 12px;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    margin-top: -1px;
    transform: scale(0.7);
  }

  .manage {
    display: block;
  }

  .sidebar {
    width: 170px;
    min-width: 170px;
  }

  .rightside {
    width: calc(100% - 170px);
  }

  .toggled+.rightside {
    width: calc(100% - 100px);
  }

  .healthcare .btn-toggle>.handle {
    left: 2px;
  }

  .sidebar-content li.active::after {
    right: calc(-100% - 12px);
  }
  
  .producttitle label {
    font-size: 12px;
  }

  .fw-18,
  .login-form label {
    font-size: 14px !important;
  }

  .optionselection .ant-checkbox+span {
    padding: 8.5px;
  }

  .anskey img {
    filter: brightness(0) invert(1);
  }

  .anskey {
    color: var(--white) !important;
    background-color: var(--healthcare-color);
    margin-right: 4px;
  }

  .iconsizeresponsive img,
  .optionselection label span img {
    height: 24px;
    width: 24px;
  }

  .plusicon {
    padding: 10px !important;
  }
  .addcreatebtn:hover{
    background-color: var(--hover-color);
  }
  .plusicon img,
  .ansSelected::after,
  .deleteset {
    height: 18px;
    width: 18px;
  }

  .faqIcon {
    padding: 0px;
    height: 25px;
    width: 25px;
  }

  .faqIcon img {
    height: 25px;
    width: 25px;
  }

  .w-cal {
    width: calc(100% - 28px);
  }

  .me-20 {
    margin-right: 10px;
  }

  .optionselection li {
    margin-bottom: 12px;
  }

  .mb-3 {
    margin-bottom: 12px !important;
  }

  .logout,
  .logout:hover {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .logout img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .ant-select {
    border: 0;
    min-height: 45px;
  }

  .login-form input {
    font-size: 14px;
  }

  .checkradiobtn {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  .onlySelection .ant-checkbox-checked+span .checkradiobtn::after {
    width: 8px;
    height: 8px;
  }

  .redirectionlink input {
    width: 100%;
  }

  .primarybtn {
    padding: 10px 18px;
    align-items: center;
  }

  .me-30 {
    margin-right: 10px;
  }

  .ant-checkbox-wrapper {
    margin-right: 10px;
  }

  .ant-upload {
    min-height: 150px;
  }

  .plus {
    width: 20px;
    height: 20px;
  }

  .plus:after {
    left: 3px;
  }

  .plus::before {
    top: 3px;
  }

  .addclaimbtn {
    padding: 10px;
  }

  .table-responsive {
    padding: 16px;
  }

  .gmeTLe {
    padding: 15px 10px !important;
  }

  .gmeTLe .item-content {
    font-size: 14px;
  }

  .btn-default.active::after {
    right: calc(-100% - 0px);
  }

  .pro-sidebar.toggled .btn-default.active::after {
    right: calc(-100% - 10px);
  }

  .sidebar-content li {
    padding: 8px 0;
    padding-left: 8px;
    font-size: 12px !important;
    align-items: center;
  }

  .sidebar-content li span {
    font-size: 12px !important;
  }

  .hoverimges {
    margin-right: 0px;
  }

  .sidebar-content .list-unstyled {
    padding: 0;
  }

  .sidebar.toggled {
    padding-left: 15px;
    padding-right: 15px;
  }

  .toggled {
    width: 100px !important;
    min-width: 100px !important;
  }

  .hoverimges img {
    height: 20px;
    width: 20px;
    margin-top: 0px;
  }

  .btn-toggle {
    height: 10px;
    width: 20px;
    margin-left: 17px;
  }

  .btn-toggle:before,
  .btn-toggle:after {
    font-size: 9px;
  }

  .btn-toggle:before {
    left: -70px;
  }

  .primecare .btn-toggle>.handle {
    left: 11px;
  }

  .btn-toggle:after {
    right: -54px;
  }

  .btn-toggle>.handle {
    width: 7px;
    height: 7px;
  }

  .switches-container .mempro,
  .switch div {
    font-size: 12px;
  }

  .mx-auto.switches-container,
  .switches-container {
    width: 180px;
  }
}

@media (max-width: 991.98px) {
  .windowScreen {
    display: none;
  }

  .mobileFont {
    display: block;
    background-color: rgba(0, 0, 0, 0.718);
    color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 300px;
  }

  .max300 {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 600;
    max-width: 500px;
    padding: 0 16px;
    text-transform: capitalize;
  }
}

@media (max-width: 575px) {
  .responsiveflex {
    grid-template-columns: repeat(1, 1fr);
  }
}