@font-face {
  font-family: 'Gotham-Bold';
  src: url('../src/font/Gotham-Bold.otf');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../src/font/Gotham-Book.otf');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../src/font/Gotham-Medium.otf');
}

body {
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px;
}

.ql-font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="montserrat"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="montserrat"]::before {
  content: "Montserrat";
}

.ql-font-roboto {
  font-family: "Roboto", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
  content: "Roboto";
}

.ql-font-arial {
  font-family: "Arial", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before {
  content: "Arial";
}

.ql-font-cursive {
  font-family: "Cedarville Cursive", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="cursive"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="cursive"]::before {
  content: "Cedarville Cursive";
}


.ql-font-courier {
  font-family: "Courier Prime", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier"]::before {
  content: "Courier Prime";
}

.ql-font-amatic {
  font-family: "Amatic SC", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="amatic"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="amatic"]::before {
  content: "Amatic SC";
}

.ql-font-josefin {
  font-family: "Josefin Sans", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="josefin"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="josefin"]::before {
  content: "Josefin Sans";
}

.ql-font-worksans {
  font-family: "Work Sans", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="worksans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="worksans"]::before {
  content: "Work Sans";
}

.ql-font-baskervville {
  font-family: "Baskervville SC", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="baskervville"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="baskervville"]::before {
  content: "Baskervville SC";
}

.ql-font-robotomono {
  font-family: "Roboto Mono", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="robotomono"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="robotomono"]::before {
  content: "Roboto Mono";
}

.ql-font-poppins {
  font-family: "Poppins", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="poppins"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="poppins"]::before {
  content: "Poppins";
}

.ql-font-mukta {
  font-family: "Mukta", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="mukta"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="mukta"]::before {
  content: "Mukta";
}

.ql-font-anton {
  font-family: "Anton SC", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="anton"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="anton"]::before {
  content: "Anton SC";
}

.ql-font-opansans {
  font-family: "Open Sans", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="opansans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="opansans"]::before {
  content: "Open Sans";
}

.ql-font-ebgaramond {
  font-family: "EB Garamond", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="ebgaramond"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="ebgaramond"]::before {
  content: "EB Garamond";
}

.ql-font-gfsdidot {
  font-family: "GFS Didot", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="gfsdidot"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="gfsdidot"]::before {
  content: "GFS Didot";
}

.ql-font-brushscript {
  font-family: "Nanum Brush Script", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="brushscript"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="brushscript"]::before {
  content: "Nanum Brush Script";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}


:root {
  --color-15345a: #15345a;
  --black: #000000;
  --healthcare-color: #9e3323;
  --button-color: #3abeb5;
  --secondary-text: #473f44;
  --login-color: #162c4e;
  --btn-color: #15345a;
  --hover-color: #15345a;
  --text-color: #15345a;
  --sidebarcolor: #f3f5f7;
  --bg-color: #f0f4f9;
  --inactive: #e95949;
  --active: #56b3e5;
  --schedule: #3abeb5;
  --upload-bg: #2191d01a;
  --white: white;
  --title-font: Gotham-Medium;
  --title-font-bold: Gotham-Bold;
  --label: #15345a;
  --liselect: #c2e5f3;
  --active-button: #008000;
  --pending-status: #E95949;
}

/* :root {
  --healthcare-color: #15345a;
  --btn-color: #CD6422;
  --text-color:#303763;
  --hover-color: #CD6422;
  --sidebarcolor: #425A7E;
  --bg-color: #F2F2F2;
  --inactive: #1A1827;
  --active: #CD6422;
  --upload-bg:#CD642226;
  --title-font: texgyrebonum;
  --title-font-bold: texgyrebonum;
  --label:white;
} */
* {

  font-family: 'Gotham-Book';
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

p>strong {
  font-family: var(--title-font);
}

a:hover {
  color: inherit;
}

.stext {
  color: var(--secondary-text);
}

.gb {
  font-family: 'Gotham-Bold';
}

.gbo {
  font-family: 'Gotham-Book' !important;
}

.gm {
  font-family: 'Gotham-Medium';
}

.fw-18 {
  font-size: 18px !important;
}

.fw-36 {
  font-size: 36px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.hv-100 {
  height: 100vh;
}

hr {
  margin: 24.5px 0;
  height: 1px;
  width: 100%;
  background-color: #cccccc;
  border: none;
}


.rsw-ce {
  min-height: 400px;
}

.bgtransparent {
  background-color: transparent;
}

.carousel-health .carousel-control-prev,
.carousel-health .carousel-control-next {
  display: none;
}

.carousel-health .carousel-inner {
  border-radius: 30px;
}

.mx-700 {
  min-width: 420px;
  margin: 0 auto;
}

.box-button {
  transition: all 0.5s !important;
  padding: 50px 15px !important;
  outline: 2px solid transparent !important;
}

.box-border input {
  display: none;
}

.box-border label {
  height: 100%;
  width: 100%;
  text-align: center;
  border: 1px solid #cccccc !important;
  /* min-height: 177px; */
  /* min-width: 187px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.box-border label img {
  width: 80%;
  padding: 50px 0;
}

.box-border input:checked+label {
  border: 2px solid var(--hover-color) !important;
}

.box-button:hover {
  background-color: transparent;
  border-color: transparent;
  outline: transparent;
}

.box-button:focus {
  outline: 2px solid var(--black) !important;
}

.p-color {
  color: var(--healthcare-color) !important;
}

.sbg {
  background-color: var(--active) !important;
}

.continuebtn:hover {
  background-color: var(--login-color) !important;
  color: var(--white) !important;
}
.minwidthfix:not(.minwidthfix:nth-child(3n)){
  border-right: 1px solid #473f4451 !important;margin-right: 30px;
}
.continuebtn {
  color: var(--btn-color);
  border-radius: 4px;
  background-color: var(--liselect) !important;
  /* margin-top: 50px; */
}
.radio-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  color: gray; /* Adjust text color if needed */
}

.continuebtn:disabled {
  cursor: not-allowed !important;
}

.login-form label {
  font-size: 18px;
  color: var(--login-color);
  font-weight: 500;
  margin-bottom: 8px;
}

.login-form input:focus,
.login-form select:focus,
.login-form textarea:focus,
.ant-select-selector:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--hover-color);
  color: var(--hover-color);
}

.login-form input {
  padding: 14px 15px;
  position: relative;
  color: #999999;
  background-repeat: no-repeat;
  background-position: 15px 15px;
  font-size: 16px;
}

.login-form select,
.login-form textarea {
  padding: 15px !important;
  position: relative;
  color: var(--hover-color);
}

#select * {
  padding: 10px;
}

.form-control,
.form-select {
  font-size: 16px;
}

.formpadding input {
  padding: 15px 15px 15px 61px;
}

.form-control:focus {
  border-color: unset !important;
  box-shadow: none !important;
}

.login-form input::placeholder {
  color: var(--hover-color);
}

.login-form input:focus::placeholder {
  color: var(--hover-color);
}

label {
  position: relative;
}

input:focus+.line::before {
  background-color: var(--hover-color);
}

.lhYbII {
  background-color: var(--sidebarcolor) !important;
  padding-left: 20px;
  padding-right: 20px;
}

.pro-sidebar.toggled {
  padding-left: 20px;
  padding-right: 20px;
}

.icon-suffix {
  /* text-align: center; */
  margin-bottom: 12px !important;
}

.pro-sidebar.toggled .icon-suffix {
  margin-bottom: 23px !important;
}

.icon-suffix {
  order: 1;
  display: block;
  width: 100%;
  cursor: pointer;
}

.head-text {
  order: 2;
  display: block;
  text-align: center;
  margin: auto;
}

.head-div {
  flex-wrap: wrap;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ebQXSx button {
  width: 100%;
  background-color: transparent !important;
  color: var(--white);
  opacity: 0.5;
  transition: all 0.5s;
}

.ebQXSx button:hover {
  opacity: 1;
}

.eETGfq::-webkit-scrollbar {
  display: none;
}

.hQSxZz {
  margin: 0 !important;
}

.gmeTLe .item-content {
  text-align: left;
}

.gmeTLe {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.sidebar-content label {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 5px;
}

.sidebar-content label:active,
.sidebar-content li:focus-visible,
.sidebar-content input:focus-visible,
button:focus-visible {
  border: none;
  outline: none;
  box-shadow: none !important;
}

.sidebar-content .btn {
  border: none !important;
}

.sidebar-content li {
  font-size: 18px;
  color: var(--color-15345a);
  opacity: 0.5;
  width: 100%;
  font-family: 'Gotham-Book' !important;
  padding: 13px 0;
  padding-left: 20px;
  cursor: pointer;
}

.sidebar-content li.disable{
  pointer-events: none;
}
.sidebar-content li:hover {
  opacity: 1;
}

.sidebar-content li.active {
  font-size: 18px;
  color: var(--text-color);
  opacity: 1;
  background-color: var(--liselect);
  border-radius: 4px;
  position: relative;
}

.hoverimges {
  height: 24px;
  width: 24px;
  margin-right: 15px;
}

.hoverimges img {
  height: 28px;
  width: 28px;
  margin-top: -4px;
}

.groupcheck {
  height: 43px;
}

.sidebar-content .list-unstyled {
  padding: 0 8px;
}

.sidebar-content li.active:hover {
  font-size: 18px;
  color: var(--text-color);
  opacity: 1;
  /* background-color: var(--white); */
  border-radius: 4px;
}

.sidebar-content label input+li:hover {
  font-size: 18px;
  color: var(--white);
  opacity: 1;
}

.sidebar-content input {
  display: none;
}

.carousel-indicators button {
  transition: all 0.5s !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  opacity: 1 !important;
}

.carousel-indicators button.active {
  width: 30px !important;
  position: relative;
}

.carousel-indicators button.active::after {
  content: '';
  background-color: var(--white);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.sidebar-content label input:checked+li div {
  position: relative;
}

.carousel.slide {
  /* height: calc(100vh - 96px); */
  /* min-height: 700px; */
  /* margin-bottom: 50px; */
  /* height: 100%; */
  /* padding: 50px 0; */
  max-width: 582px;
}

.box-border {
  cursor: pointer;
}

.rightside {
  background-color: var(--bg-color);
  width: calc(100% - 270px);
  margin-left: auto;
  height: 100%;
  transition: all 0.3s;
}

.gmeTLe {
  height: unset !important;
}

.toggled+.rightside {
  width: calc(100% - 136px);
}

.toggled .logout span {
  display: none;
}

.toggled .logout img {
  margin: 0;
}

.head-div.toggled .head-text {
  display: block !important;
}

.lhYbII.toggled {
  width: 136px !important;
  min-width: 136px !important;
}

.rightcontent {
  width: 100%;
  padding: 40px 30px;
}

.breadcrumb-item {
  font-size: 16px;
}

.breadcrumb-item a {
  font-size: 16px;
  color: #999999 !important;
  text-decoration: none !important;
}

.breadcrumb-item.active {
  color: var(--login-color) !important;
}

.table-padding {
  background-color: var(--white);
  border-radius: 8px;
}

.headtitle {
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heedprodct {
  font-size: 22px;
  color: var(--text-color);
  font-weight: 900;
  font-family: var(--title-font-bold);
  /* margin-top: 20px; */
}

.plus {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-bottom: 0;
  background: transparent;
  transition: all 0.5s;
  margin-right: 5px;
  background-image: url(./assets/plus.svg);
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.textgrey {
  color: #999999;
}

.primarybtn {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: var(--btn-color);
  padding: 15px 21px;
  border-radius: 4px;
  display: flex;
  color: var(--white);
}

.primarybtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primarybtn:hover {
  background-color: var(--btn-color);
  color: var(--white);
}

.primarybtn:hover .plus {
  transform: rotate(90deg);
}

.pdfOnly {
  color: var(--healthcare-color);
}

/* .addcreatebtn:hover img {
  filter: brightness(0) invert(1);
} */

.table-responsive {
  padding: 25px 30px;
}

.table .ant-table-column-title,
.table th {
  font-family: 'Gotham-Medium';
  color: var(--text-color);
}

.table th,
.table td {
  background-color: transparent !important;
  padding: 25px !important;
}

.table th::before {
  width: 0px !important;
}

.table td {
  font-size: 16px;
}

.inactive {
  padding: 10px;
  font-size: 14px;
  background-color: var(--inactive);
  width: fit-content;
  color: var(--white);
  border-radius: 4px;
  white-space: nowrap;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.manage {
  padding: 10px;
  font-size: 14px;
  background-color: #f2f2f2;
  width: 78px;
  border-radius: 4px;
  border: 1px solid #999999;
  color: #473f44;
  white-space: nowrap;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}


.downloadReports {
  padding: 10px;
  font-size: 14px;
  background-color: #f2f2f2;
  width: 90px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #999999;
  color: #473f44;
  white-space: nowrap;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.failed {
  padding: 10px;
  font-size: 14px;
  background-color: var(--healthcare-color);
  width: fit-content;
  color: var(--white);
  border-radius: 4px;
  white-space: nowrap;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.activted {
  padding: 10px;
  font-size: 14px;
  background-color: var(--liselect);
  width: fit-content;
  color: var(--hover-color);
  border-radius: 4px;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.sent {
  padding: 10px;
  font-size: 14px;
  background-color: var(--active-button);
  width: fit-content;
  color: var(--white);
  border-radius: 4px;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.pending {
  padding: 10px;
  font-size: 14px;
  background-color: var(--pending-status);
  width: fit-content;
  color: var(--white);
  border-radius: 4px;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.schedule {
  padding: 10px;
  font-size: 14px;
  background-color: var(--active);
  width: fit-content;
  color: var(--white);
  border-radius: 4px;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500;
}

.manage:hover {
  background-color: #cccccc;
}

.logout,
.logout:hover {
  color: var(--color-15345a);
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  cursor: pointer;
}

.logout img {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.gmeTLe.toggled {
  padding: 0;
}

.gmeTLe.toggled .side-icon {
  margin: 0;
}

.rightcontent {
  background-color: var(--bg-color);
}

.btn-toggle:hover {
  background-color: var(--color-15345a);
}

.btn-toggle {
  margin-left: 20px;
  padding: 0;
  position: relative;
  border: none;
  height: 20px;
  width: 40px;
  border-radius: 1.5rem;
  color: #6b7381;
  background: var(--color-15345a);
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 14px;
  transition: opacity 0.25s;
  font-weight: 600;
}

.btn-toggle:before {
  content: 'Healthcare2u';
  left: -106px;
  color: var(--color-15345a);
}

.btn-toggle:after {
  content: 'PrimeCare';
  right: -83px;
  opacity: 0.5;
  color: var(--color-15345a);
}

.btn-toggle.active {
  background-color: var(--white);
}

.btn-toggle>.handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.3875rem;
  width: 13px;
  height: 13px;
  border-radius: 1.125rem;
  background: var(--white);
  transition: left 0.25s;
  border: 1px solid #ccc;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active>.handle {
  left: 21px;
  transition: left 0.25s;
}

/* .btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
} */
.primecare .btn-toggle:after {
  opacity: 1;
}

.primecare .btn-toggle::before {
  opacity: 0.5;
}

.toggled .btn-toggle {
  display: none;
}

.btn-default.active {
  overflow: unset;
}

.sidebar-content li.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  right: calc(-100% - 21px);
  z-index: 5555;
  background-color: var(--liselect);
}

.sidebar {
  overflow: hidden auto;
  position: fixed !important;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.eETGfq {
  overflow: unset !important;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.optionflex {
  padding: 25px 30px;
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionrow {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.optionrow .option {
  width: 100%;
}

.optiontext {
  font-family: 'Gotham-Medium';
  margin-right: 5px;
}

.switches:where(.css-dev-only-do-not-override-1ae8k9u).ant-switch.ant-switch-checked {
  background: var(--active);
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: var(--active);
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-switch .ant-switch-handle {
  width: 15px;
  height: 15px;
  left: 4px;
  top: 3px;
}

.savebtn {
  padding: 15px 36px;
}

.productname {
  color: #999999;
  font-size: 16px;
  white-space: nowrap;
}

.producttitle {
  color: var(--text-color);
  font-family: 'Gotham-Medium';
  font-size: 18px;
}

.centerline {
  margin: 0 40px;
}

.lineafter {
  position: relative;
  padding-right: 40px;
  margin-right: 40px;
}

.lineafter::after {
  content: '';
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #cccccc;
  position: absolute;
}

.nav-link:focus,
.nav-link:hover,
.nav-link {
  color: var(--hover-color) !important;
}

.nav-link {
  font-size: 16px !important;
}

.nav-link.active {
  font-weight: 600;
}

.tabbing ul {
  padding: 30px 30px 0 30px;
  border-bottom: 1px solid var(--hover-color);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  position: relative;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active::after {
  border: none;
  content: '';
  width: 100%;
  height: 4px;
  background-color: var(--hover-color);
  top: calc(100% - 2px);
  left: 0;
  position: absolute;
  border-radius: 2px;
}

.tab-content {
  padding: 30px;
}

/* upload css */

.upload-area {
  background-color: var(--white);
  min-height: 378px;
  border: 1px dashed var(--hover-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.allstate,
.allstate .ant-upload {
  min-height: unset;
  padding: 10px;
}

.ant-upload-hint {
  display: none;
}

.ant-upload-text,
.ant-upload-text u {
  color: var(--login-color) !important;
  font-size: 18px !important;
  font-family: 'Gotham-Medium';
}

.ant-upload-drag {
  /* display: flex;
  align-items: center; */
  text-align: center;
}

.removehtml {
  text-align: center;
  background-color: #ebebeb;
  border-radius: 8px;
  color: #999999;
}

.ant-upload-list-item-container {
  padding: 20px;
  background-color: var(--upload-bg);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-select-outlined .ant-select-selector {
  box-shadow: none !important;
}

.ant-upload-list-item {
  margin-top: 0 !important;
}

.ant-upload-icon svg,
.ant-btn-icon svg {
  display: none;
}

.ant-upload-icon {
  height: 30px;
  width: 30px;
  position: relative;
  margin-right: 15px;
}

.ant-upload-icon::before {
  content: '';
  height: 100%;
  width: 100%;
  background: url(./assets/uploadicon.svg);
  position: absolute;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  height: unset;
}

.ant-upload-list-item-name {
  text-decoration: underline !important;
  color: var(--login-color);
  font-weight: 700;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
  opacity: 1;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: unset;
}

.ant-btn[title='Remove file'] {
  height: 40px !important;
  width: 40px !important;
  position: relative;
  border-radius: 50%;
}

.ant-btn[title='show file'] {
  height: 40px !important;
  width: 40px !important;
  position: relative;
  border-radius: 50%;
}

.ant-btn[title='Remove file']::after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  background: url(./assets/deleteicon.svg);
  position: absolute;
  left: 0;
}

.ant-btn[title='show file']::after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  background: url(./assets/eye.svg);
  position: absolute;
  left: 0;
}

.p-30 {
  padding: 30px;
}

.editbuttom {
  color: var(--white);
  background-color: var(--hover-color);
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
}

.lineafter::after {
  content: '';
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #cccccc;
  position: absolute;
}

.d-4grid .verticalline {
  height: 63px;
  width: 1px;
  background-color: #cccccc;
}

.d-4grid>div {
  width: 25%;
}

.overlayblur .optionflex {
  position: relative;
}

.overlayblur .optionflex::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.596);
}

.continuebtn.disable {
  pointer-events: none;
  background-color: #3abeb577 !important;
}

.mx750 .d-4grid {
  max-width: 750px;
  margin: 0 auto;
}

td .table td {
  padding: 0 !important;
}

.ant-select-selector input,
.ant-select-selector {
  height: 56px !important;
}

.ant-select-selection-item {
  font-size: 16px;
  color: var(--hover-color);
}

.ant-select-item-option-content {
  color: var(--hover-color);
  padding: 8px 15px;
}

.ant-select-item-option {
  position: relative;
  margin: 6px 0;
}

.ant-select-dropdown .ant-select-item-option:last-child::after {
  background-color: transparent;
}

.ant-select-item-option::after {
  position: absolute;
  content: '';
  width: calc(100% - 35px);
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: #cccccc;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-dropdown {
  padding: 0;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #15345a14;
  margin-bottom: 6px;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 15px;
}

.ant-select-focused .ant-select-selector {
  border-color: unset !important;
}

.ant-select-open .ant-select-selector {
  border-color: var(--hover-color) !important;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

.ant-select-open .ant-select-arrow svg {
  fill: var(--hover-color);
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #d9d9d9;
}

.ant-select {
  border: 0;
  min-height: 56px;
}

.cancelbtn,
.cancelbtn:active {
  font-size: 14px;
  color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;

  background-color: var(--white) !important;
}

.cancelbtn:hover {
  font-size: 14px;
  color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;

  background-color: var(--white) !important;
}

.bgt .cancelbtn,
.bgt .cancelbtn:hover,
.bgt .cancelbtn:active {
  background-color: transparent !important;
}

.okbtn,
.okbtn:hover,
.okbtn:active {
  font-size: 18px;
  color: var(--white) !important;
  border: 1px solid var(--hover-color) !important;
  padding: 14px 50px;
  background-color: var(--hover-color) !important;
}

.ant-modal-close {
  border-radius: 50% !important;
  border: 1px solid #cccccc !important;
}
.mx550{
  width: 570px !important;
}
.ant-modal-close svg {
  font-size: 10px;
}

.ant-modal-content {
  padding: 40px 30px !important;
}

.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ql-editor,
.removehtml {
  min-height: 300px;
}

.removehtml {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ptext {
  color: var(--hover-color);
}

.form-check-input:focus {
  border-color: var(--active);
  box-shadow: unset;
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--active);
  border-color: var(--active);
}

.form-check-input:hover {
  background-color: #cccccc;
}

.me-30 {
  margin-right: 30px;
}

.sectionpadding {
  padding: 30px;
  background-color: var(--white);
  border-radius: 8px;
}

.selectnone .ant-select-selector+span {
  display: none;
}

.selectnone .ant-select-selector {
  background-color: transparent !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none;
}

.antselect {
  padding-bottom: 60px;
}

.certifiupload .ant-upload-drag-icon {
  height: 24px;
  width: 24px;
  margin-right: 15px;
  margin-bottom: 0 !important;
}

.certifiupload .ant-upload-drag-icon img {
  height: 24px;
  width: 24px;
}

.certifiupload .ant-upload-drag-container {
  display: flex !important;
  justify-content: center;
}

.certifiupload .ant-upload-text {
  font-size: 17px;
}

.certifiupload .ant-upload-btn {
  padding: 0px !important;
}

.mt-50 {
  margin-top: 50px;
}

.sectionpadding .ant-select-arrow {
  height: unset;
}

.sectionpadding .ant-select-open .ant-select-arrow {
  transform: unset;
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  background-color: var(--healthcare-color);
  color: var(--white);
  font-size: 14px;
  border-radius: 29px;
  min-height: 32px;
}

.ant-select-outlined.ant-select-multiple .anticon-close svg {
  fill: var(--white);
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border: 1px solid #d9d9d9;
}

.ant-select-outlined.ant-select-multiple .ant-select-clear {
  transform: translateY(-50%);
  margin: 0;
  height: 18px !important;
  width: 18px !important;
}

.ant-select-selection-item .ant-select-selection-item-content {
  font-size: 14px;
  padding-top: 4px;
}

.ant-select-selection-item .ant-select-selection-item-remove svg {
  font-size: 14px;
}

.cancelbtn {
  background-color: transparent;
}

.addclaimbtn {
  color: var(--login-color);
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  padding: 18px;
}

.addclaimbtn img {
  margin-right: 10px;
}

.addclaimbtn:hover {
  color: var(--white) !important;
  background-color: var(--active);
}

.addclaimbtn:hover span {
  color: var(--white) !important;
}

.addclaimbtn:hover img {
  filter: brightness(0) invert(1);
}

.anskey {
  color: var(--healthcare-color);
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  padding: 18px;
}

.anskey img {
  margin-right: 10px;
}

.anskey:hover {
  color: var(--white) !important;
  background-color: var(--healthcare-color);
}

.anskey:hover span {
  color: var(--white) !important;
}

.anskey:hover img {
  filter: brightness(0) invert(1);
}

.addcreatebtn {
  color: var(--login-color);
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  padding: 12px 18px;
}

.addcreatebtn img {
  margin-right: 4px;
}

.addcreatebtn:hover {
  /* color: white !important; */
  background-color: #C2E5F3
}

/* .addcreatebtn:hover span {
  color: white !important;
} */

.redirectionlink input {
  width: 50%;
}



.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mx-750 .d-4grid {
  max-width: 750px;
  margin: auto;
}

.mx-750 .d-4grid>div:not(.verticalline) {
  width: unset;
}

.activetext {
  color: var(--login-color);
}

.selecttype {
  min-width: 350px;
}

.selectdisplay>button:not(:last-child) {
  margin-right: 10px;
}

.optionselection li {
  list-style-type: none;
  list-style-position: outside;
  width: 100%;
  margin-bottom: 20px;
}

.optionselection input[type='checkbox']:not(:checked),
.optionselection input[type='checkbox']:checked {
  position: absolute;
  left: -9999%;
}

.optionselection .ant-checkbox+span {
  padding: 13.5px;
  cursor: pointer;
  border: 1px solid #cccccc;
  color: var(--secondary-text);
  background-color: var(--white);
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.ant-checkbox-wrapper input {
  color: var(--color-15345a) !important;
}

.onlySelection .ant-checkbox-checked+span {
  border: 1px solid var(--hover-color);
  color: var(--hover-color);
  background-color: #f2f2f2;
  position: relative;
}

.selectans input[type='checkbox']:checked+label {
  border: 1px solid var(--active);
  color: var(--secondary-text);
  background-color: #3abeb533;
  position: relative;
}

.checkradiobtn {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 2px solid var(--btn-color);
  /* position: relative; */
  margin-right: 18px;
}

.optionselection .ant-checkbox-wrapper {
  width: 100%;
}

.optionselection .ant-checkbox {
  display: none !important;
}

.optionselection span {
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-checkbox+span .checkradiobtn {
  position: relative;
}

.ant-checkbox+span img {
  visibility: hidden;
  pointer-events: none;
}

/* .ant-checkbox-checked+span img {
  visibility: visible;
  pointer-events: all;
} */

.onlySelection .ant-checkbox-checked+span .checkradiobtn::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--hover-color);
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  border-radius: 50%;
}

.ansSelected .ant-checkbox+span .checkradiobtn::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--hover-color);
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  border-radius: 50%;
}

.checkradiobtn.d-inline-block {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.me-20 {
  margin-right: 20px;
}

.ms-20 {
  margin-left: 20px;
}

.optionselection input[type='radio']:not(:checked),
.optionselection input[type='radio']:checked {
  position: absolute;
  left: -9999%;
}

.nav-tabs .nav-item button {
  border: none !important;
}

.optionselection input[type='radio']+label {
  padding: 18px;
  cursor: pointer;
  border: 1px solid #cccccc;
  color: var(--secondary-text);
  background-color: var(--white);
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.optionselection input[type='radio']+label>.checkradiobtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--btn-color);
  margin-right: 18px;
}

.optionselection input[type='radio']:checked+label {
  border: 1px solid var(--active);
  color: var(--secondary-text);
  background-color: #56b3e51a;
  /* position: relative; */
}

.ansSelected .ant-checkbox+span {
  border: 1px solid var(--active);
  color: var(--secondary-text);
  background-color: #56b3e51a;
  /* position: relative; */
}

.optionselection input[type='radio']:checked+label>.checkradiobtn::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url(./assets/selectans.svg);
  background-size: 100%;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.optionselection input[type='text'] {
  padding: 0;
  border: none;
  background-color: transparent;
  width: calc(100% - 50px);
}

.optionselection input[type='text']::placeholder {
  color: var(--secondary-text);
}

.optionselection input[type='checkbox']+label input[type='text'] {
  pointer-events: none;
  color: var(--secondary-text);
}

.optionselection input[type='checkbox']:checked+label input[type='text'] {
  pointer-events: all;
}

.optionselection label span img {
  display: block;
  transition: transform 0.5s;
}

.optionselection label span img:hover {
  transform: scale(1.2);
}

.w-cal {
  width: calc(100% - 38px);
}

.ansSelected::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url(./assets/selectans.svg);
  background-size: 100%;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.userpersonal .producttitle {
  /* max-width: 178px;
  width: 178px; */
  word-wrap: break-word;
}

.ant-checkbox-checked+span img {
  z-index: 1;
}

.optionselected {
  color: #999999;
  font-size: 16px;
  margin-left: 15px;
}

.editmodeon label {
  color: #cccccc;
}

.editmodeon .optionselection label span {
  border: none;
  font-size: 16px;
  pointer-events: none;
}

.editmodeon .optionselection li {
  margin-bottom: 10px;
}

.editmodeon input {
  border: none;
  padding-left: 0;
  pointer-events: none;
}

.editmodeon label {
  pointer-events: none;
}

.disabledbtn {
  position: relative;
  pointer-events: none;
  cursor: not-allowed;
}

.disabledbtn::after {
  content: '';
  position: absolute;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.212);
  left: 0;
  top: 0;
  z-index: 2;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color) !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  padding-bottom: 24px !important;
  border-bottom: 1px solid #cccccc !important;
}

.accordion-button:focus,
.accordion-button:focus-within,
.accordion-button:focus-visible {
  box-shadow: unset !important;
}

.accordion-item {
  margin-bottom: 30px;
  border: none;
  border-radius: 8px !important;
  padding: 0px;
  overflow: hidden;
}

.accordion-button {
  padding: 30px !important;
  font-size: 18px !important;
  color: var(--btn-color) !important;
  border-radius: 8px;
}

.accordion-body {
  padding: 0 !important;
  padding-top: 25px !important;
  padding-bottom: 30px !important;
  margin: 0 30px 0px;
}

.accordion-button::after {
  background-image: url(./assets/Arrow_right.svg) !important;
  background-size: 100% !important;
  height: 24px;
  width: 24px;
  transform: scale(1.2) rotate(90deg) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/Arrow_right.svg) !important;
  background-size: 100% !important;
  height: 24px;
  width: 24px;
  transform: scale(1.2) rotate(-90deg) !important;
}

.accordion-collapse:focus-within {
  outline: none;
}

.switches-container {
  width: 238px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--white);
  line-height: 2rem;
  border-radius: 4px;
  margin-left: auto;
  font-size: 16px;
  border: 1px solid #cccccc;
  /* padding: 8px; */
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container .mempro {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  color: var(--text-color);
  font-size: 16px;
  padding: 7px 15px;
}

.switches-container .mempro.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.switch {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% - 4px);
  z-index: 3;
  transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
  border-radius: 4px;
  /* height: 100%; */
  background-color: var(--hover-color);
  margin: 4px !important;
}

/* switch box highlighter */

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  /* opacity: 0; */
  display: block;
  color: var(--white);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  /* position: absolute; */
  top: 0;
  left: 0;
  padding: 10px 15px;
  font-size: 16px;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switchside.switch-wrapper {
  transform: translateX(100%);
}

/* .switchside .switch .swipro{
  display: block;
}
.switchside .swimem{
  display: none;
}

.switch .swipro{
  display: none;
} */
.editfaq textarea:focus {
  color: var(--hover-color);
}

.breadcrumb-item+.breadcrumb-item::before {
  content: url('./assets/breadcrum.svg') !important;
  margin-top: 3px;
}

.userpage hr {
  background-color: #15345a33;
}

.userpage :where(.css-dev-only-do-not-override-1ae8k9u).ant-table-wrapper .ant-table-thead>tr>th,
.userpage :where(.css-dev-only-do-not-override-1ae8k9u).ant-table-wrapper .ant-table-thead>tr>td,
.userpage :where(.css-dev-only-do-not-override-1ae8k9u).ant-table-wrapper .ant-table-tbody>tr>th,
.userpage :where(.css-dev-only-do-not-override-1ae8k9u).ant-table-wrapper .ant-table-tbody>tr>td {
  border: none;
}

.usertable td {
  min-width: 170px;
}

.usertable .emailtable {
  min-width: 200px;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-content table {
  width: 100%;
}

.usercard {
  background-color: var(--white);
  border-radius: 8px;
}

.usernamedetail {
  padding: 30px;
  display: flex;
  align-items: center;
  font-family: 'Gotham-Medium';
}

.userotherdetail {
  padding: 30px;
}

.usernamedetail .userimg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.username {
  color: var(--hover-color);
  margin-right: 60px;
}

.btn-toggle:before {
  content: 'Healthcare2u';
  left: -106px;
  color: var(--color-15345a);
}

.btn-toggle:after {
  content: 'PrimeCare';
  right: -85px;
  opacity: 0.5;
  color: var(--color-15345a);
}

.line60 .verticalline {
  height: 40px;
  margin-left: 60px;
  margin-right: 60px;
  width: 1px;
  background-color: #15345a33;
  margin-top: auto;
  margin-bottom: auto;
}

.my-40 {
  margin: 40px 0;
}

.mx850 .d-4grid {
  max-width: 850px;
  margin: 0 auto;
}

.mx-auto.switches-container {
  width: 260px;
  border-radius: 8px;
}

.m300 {
  width: 400px;
}

.truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.headdetail {
  background-color: var(--login-color);
  border-radius: 8px 8px 0px 0px;
  padding: 12px 32px;
  width: fit-content;
  font-size: 16px;
  color: var(--white);
}

.seprator {
  width: 100%;
  height: 1px;
  background-color: #CCCCCC;
  margin: 30px 0px;
}

.ant-switch.ant-switch-checked {
  background-color: var(--active);
}

.mtm {
  margin-top: -4px;
}

.my-20 {
  margin: 20px 0;
}

.producttitle label {
  font-size: 16px;
}

.notchange {
  font-size: 16px;
  color: var(--inactive);
  min-height: 43px;
}

.labelp {
  color: var(--hover-color);
  font-family: 'Gotham-Medium';
}

.noicon .ant-picker-suffix {
  display: none;
}

.noicon {
  width: 100%;
  color: var(--hover-color);
}

.noicon .ant-picker {
  width: 100%;
  padding: 15px;
}

.noicon .ant-picker:hover {
  border-color: inherit;
  box-shadow: none;
}

.noicon .ant-picker:focus,
.noicon .ant-picker:focus-within {
  border-color: inherit;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-picker .ant-picker-input>input {
  font-size: 16px;
}

.fs-16 {
  font-size: 16px;
}

.editmodeoff input,
.editmodeoff textarea,
.editmodeoff .ant-select-selector,
.editmodeoff .ant-space-item {
  pointer-events: none;
}

th {
  white-space: nowrap;
}

.selectdisplay button {
  background: transparent;
  border: none;
  padding: 0;
}

.selectdisplay {
  display: none;
}

.selectdisplayed:focus+.selectdisplay {
  display: block;
}

.cvIITU {
  padding: 0 !important;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-modal-root .ant-modal-wrap {
  z-index: 9999;
}

.ant-modal-content {
  padding: 25px !important;
}

hr {
  margin: 15px 0;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-modal {
  top: 50%;
  transform: translateY(-50%);
}

.primecare .btn-toggle>.handle {
  left: 23px;
  transition: left 0.25s;
}

.primecare .btn-toggle>.handle::after {
  opacity: 1;
}

.healthcare .btn-toggle>.handle {
  left: 5px;
}

.healthcare .btn-toggle>.handle::before {
  opacity: 1;
}

.primecare .healthcareImg,
.healthcare .primecareImg {
  display: none;
}

.sidebar {
  background-color: var(--sidebarcolor) !important;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(255, 255, 255);
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s,
    all 0.3s ease 0s;
  position: relative;
  z-index: 1009;
  height: 100%;
  overflow: hidden auto;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.head-div {
  padding: 24px 30px;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 1px;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  white-space: nowrap;
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.sidebar-content {
  padding-top: 15px;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.toggled {
  width: 136px !important;
  min-width: 136px !important;
}

.sidebar.toggled {
  padding-left: 20px;
  padding-right: 20px;
}

.toggled .item-content {
  display: none;
}

.healthcare .normalimg,
.healthcare .hoverimg,
.primecare .hQSxZz .selectimg,
.primecare .hQSxZz.active .normalimg {
  display: none;
}

.primecare .hQSxZz .normalimg,
.primecare .hQSxZz.active .selectimg {
  display: block;
}

.primecare .hoverimges img {
  margin-top: 0;
}

.healthcare .sidebar {
  box-shadow: #15345a1a 4px 4px 20px;
}

.healthcare .logoutprime,
.primecare .logouthealth {
  display: none;
}

.healthcare .logouthealth,
.primecare .logoutprime {
  display: block;
}

.ant-upload {
  min-height: 378px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.imgpo {
  position: relative;
}

.emailicon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  filter: grayscale(1);
  opacity: 0.5;
}

.line::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 1px;
  font-size: 20px;
  background-color: #999999;
  z-index: 99999;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
}

input:focus+.emailicon {
  opacity: 1;
  filter: grayscale(0);
}

.toggled .hoverimges {
  margin: 0;
}

.toggled .sidebar-content li {
  padding: 13px 20px;
  justify-content: center;
}

.ant-upload-drag {
  border-color: var(--btn-color) !important;
}

.ant-upload-drag:hover {
  background-color: var(--upload-bg) !important;
}

.ant-checkbox-input,
.ant-checkbox,
.ant-checkbox-inner {
  height: 20px !important;
  width: 20px !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--liselect) !important;
  border-color: transparent;
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: var(--btn-color);
}

.ant-checkbox+span {
  padding-right: 0;
}

.ant-picker-time-panel-column[data-type='second'] {
  display: none;
}

.ant-picker-header {
  color: white;
  background-color: var(--login-color);
}

.ant-picker-cell-inner,
.ant-picker-content th,
.ant-picker-now-btn,
.ant-picker-ok span {
  font-size: 14px !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-header-prev-btn,
.ant-picker-header-next-btn {
  transform: scale(1.2);
}

.ant-picker-dropdown .ant-picker-prev-icon::before,
.ant-picker-dropdown .ant-picker-next-icon::before,
.ant-picker-dropdown .ant-picker-super-prev-icon::before,
.ant-picker-dropdown .ant-picker-super-next-icon::before {
  border-color: white;
}

.ant-picker-month-btn,
.ant-picker-year-btn,
.ant-picker-header-view {
  color: white !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: var(--liselect);
  color: inherit;
  border-radius: 50%;
}

.ant-picker-panel-container {
  border-radius: unset;
}

.memberTableProduct table .ant-table-column-sorters,
.memberTableProduct table th,
.memberTableProduct table .ant-table-tbody tr,
.memberTableProduct table td {
  max-width: 250px !important;
  width: 100% !important;
  display: inline-block;
}

.memberTableProduct table .ant-table-column-sorters {
  display: inline-flex;
}

.ant-picker-dropdown .ant-picker-panel-container {
  border: 1px solid var(--login-color);
}

.primecare .continuebtn {
  color: var(--white);
}

.mx150 {
  max-width: 150px;
  margin: 0 auto;
}

.ant-picker-cell-today .ant-picker-cell-inner {
  /* color: white !important; */
  opacity: 1;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 0 !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body th {
  padding: 0 !important;
  background-color: var(--login-color);
  color: var(--white) !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--login-color) !important;
  border-radius: 50% !important;
  background-color: #15345a1a;
}

.ms-50 {
  margin-left: 50px;
}

.me-50 {
  margin-right: 50px;
  position: relative;
}

.mx-50 {
  margin: 0 50px;
  position: relative;
}

.producttitle img {
  margin-left: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-switch[aria-checked='false'] {
  background-color: #c2e5f3;
}

.py3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: var(--hover-color);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ant-table-cell {
  background-color: white !important;
}

.ant-table-cell::before {
  content: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.loaderBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999 !important;
}

/* .loaderArea {
  background-color: var(--red);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
}

.loaderArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  animation: ripple 1s ease-in-out infinite forwards;
  border-radius: 50%;
}

.loaderArea::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  animation: ripple 1s 1.3s ease-in-out infinite forwards;
  border-radius: 50%;
}
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(6);
    opacity: 0;
  }
} */
.loaderArea {
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #ccc;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loaderArea::after,
.loaderArea::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: var(--healthcare-color);
  border-radius: 50%;
  animation: animloaderArea 1s infinite ease-in-out;
}

.loaderArea::before {
  background-color: var(--btn-color);
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloaderArea {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

.primecare .loaderArea::after {
  background-color: var(--inactive);
}

.primecare .loaderArea::before {
  background-color: var(--login-color);
}

hr {
  border-color: #cccccc !important;
}

.form-select {
  border: none !important;
}

.ant-modal-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.ant-modal {
  top: 0;
}

.ant-modal-mask,
.ant-modal-wrap {
  min-height: 500px;
}

.manage:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.manage:disabled:hover {
  background-color: #f2f2f2;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: transparent;
}

.ant-table-content {
  white-space: nowrap;
  /* Optional: to prevent wrapping of table cell content */
}

/* Optionally, you can style the scrollbar */
.ant-table-content::-webkit-scrollbar {
  width: 12px !important;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 6px !important;
}

.ant-table-content::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

.cancelbtn.py3 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.liactivetext {
  color: var(--active);
}

.hoversection {
  border: 1px solid transparent !important;
}

.hoversection:hover {
  border: 1px solid var(--login-color) !important;
}

.userintial {
  background-color: var(--active);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: var(--white);
  font-weight: 700;
}

.memberTableProduct .ant-table-empty table .ant-table-column-sorters,
.memberTableProduct .ant-table-empty table th,
.memberTableProduct .ant-table-empty table td {
  display: table-cell;
  max-width: unset !important;
  width: unset !important;
}

.memberTableProduct .ant-table-empty table .ant-table-column-sorters {
  display: flex;
}

.memberTableProduct .ant-table-empty table .ant-table-tbody tr {
  display: table-row;
}

.mt-30 {
  margin-top: 30px;
}

.overlayblur .overlayinput {
  position: relative;
}

.overlayblur .overlayinput::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.596);
}

th.ant-table-cell,
th.ant-table-cell .ant-table-column-title {
  color: var(--login-color) !important;
  font-family: 'Gotham-Medium' !important;
  font-weight: 500 !important;
}

td.ant-table-cell {
  font-size: 16px !important;
}

.userid {
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid var(--healthcare-color);
  color: var(--healthcare-color);
}

.userid:hover {
  color: var(--healthcare-color);
}

.detailtitle {
  color: var(--healthcare-color);
  font-size: 16px;
 font-weight: 700;

}

.w-x-50 {
  width: 50%;
  min-height: 550px;
}

.min700 {
  min-height: 550px !important;
}

.login-padding {
  padding: 0 50px !important;
}

.dflex {
  display: flex;
}

.login-padding .py3 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.mobileFont {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}



.disclaimerBtn {
  position: absolute;
  right: 0;
  bottom: -87px;
}

.btnPosition {
  position: relative;
  margin-bottom: 87px;
}

.ant-pagination .ant-pagination-prev {
  order: 3;
}

.ant-pagination .ant-pagination-next {
  order: 4;
}

.ant-pagination .ant-pagination-total-text {
  order: 2;
}

.ant-pagination .ant-select-selector input,
.ant-pagination .ant-select-selector,
.ant-pagination .ant-select {
  height: unset !important;
  min-height: unset;
}

.ant-pagination .ant-pagination-options {
  margin-right: 10px;
}


.ant-pagination .ant-pagination-options::before {
  content: 'Rows per page';
  margin-right: 10px;
}

.ant-pagination {
  margin-top: 20px !important;
}

.ant-pagination .anticon-search {
  display: none;
}

.faqIcon {
  border: none;
  background-color: transparent;
  box-shadow: none;
  transition: all 0.3s;
  height: 32px;
  width: 32px;
  padding: 0;
}

.faqIcon img {
  width: 100%;
  height: 100%;
}

.faqIcon:hover {
  transform: scale(1.2);
}

/* .ant-message-notice-content{
  background-color: var(--btn-color) !important;
  padding: 12px 20px !important;
}
.ant-message-notice-content span{
  color: white !important;
}
.ant-message-notice-content .anticon{
  display: none;
} */
/* .ant-select-selection-overflow {
  top: calc(100% + 10px);
} */

.ant-select-selection-overflow-item-suffix .ant-select-selection-search-input {
  height: unset !important;
}

.state-wise .ant-select-selector {
  /* height: 100% !important; */
  padding: 9px !important;
  max-height: 100px;
  overflow-y: auto;
}

.editFaqSpacing .rightside {
  width: 100%;
  margin: 0;
}

.editFaqSpacing .rightcontent {
  padding: 0;
}

.table-search-input .ant-input-prefix {
  display: inline !important;
  margin-right: 10px;
}

.table-search-input {
  display: flex !important;
  height: 56px;
  align-items: center;
}

.table-search-input .ant-input-prefix img {
  filter: grayscale(1)
}

.table-search-input.ant-input-outlined:hover,
.table-search-input.ant-input-outlined:focus-within {
  border-color: var(--color-15345a) !important;
  box-shadow: none !important;
}

.table-search-input .ant-input-prefix img {
  filter: contrast(0);
}

.table-search-input:hover .ant-input-prefix img {
  filter: none;
}

.hunset .ant-upload-drag {
  height: unset !important;
}

.hunset {
  height: 100%;
  align-self: self-end;
}

.hunset .ant-upload-list-item-container {
  padding: 13px
}

.hunset .ant-upload-list-item-container img {
  height: 30px;
  width: 30px;
}

.state-wise.btnPosition {
  margin-bottom: 117px;
}

.collaps {
  display: none;
}

.collaps,
.expand {
  margin-right: 15px;
}

.position-error {
  position: absolute !important;
}

.collapsevent .collaps {
  display: block;
}

.collapsevent .expand {
  display: none;
}

:focus-visible {
  outline: none;
}

.collapssection .hidesection {
  display: none !important;
}

/* Notifications */

.notification-type {
  font-size: 18px;
  color: #15345a;
}

.custom-radio input[type='radio']:checked+.custom-control-label::before {
  background-color: #15345a;
}

.custom-radio input,
.custom-radio label {
  cursor: pointer;
}

.custom-radio .form-check-input:focus {
  outline: none !important;
  box-shadow: none !important
}

.action-icon {
  transition: 0.5s;
}

.action-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.optionselection .ant-checkbox-wrapper:hover span .deleteBtnClass img {
  display: block;
  transition: transform 0.5s;
  visibility: visible;
  pointer-events: all;
}

.form-check-input:checked {
  background-color: var(--btn-color) !important;
  border-color: var(--btn-color) !important;
}

.error {
  color: red;
}

.ant-select-dropdown .rc-virtual-list-holder {
  overflow: hidden !important;
  /* max-height: unset !important; */
}

.ant-select-dropdown .rc-virtual-list-scrollbar {
  display: none;
}

.ant-select-dropdown .rc-virtual-list-holder>div {
  overflow-y: auto !important
}

.ant-select-dropdown .rc-virtual-list-holder>div::-webkit-scrollbar {
  display: none;
}

.addBtnQuestion {
  position: absolute;
  top: -120px;
  right: 30px;
}

.ql-snow .ql-picker-label::before,
.ql-picker-item::before {
  font-size: 15px;
}

.ql-snow .ql-picker.ql-font {
  width: calc(100% + 19px) !important;
}

.ql-formats {
  display: block !important;
}

.ql-toolbar {
  display: flex !important;
  align-items: center;
}

.ql-font .ql-picker-label,
.ql-header .ql-picker-label,
.ql-color .ql-picker-label,
.ql-background .ql-picker-label {
  display: flex !important;
}

.ql-picker-options {
  max-height: 300px;
  overflow-y: auto;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-top: 8px !important;
}

.ant-pagination-options .ant-select-selection-item {
  margin-right: 20px;

}

.ant-pagination-options .ant-select-selector {
  position: relative;
}

.ant-pagination-options .ant-select-selector::before {
  content: "";
  position: absolute;
  height: 17px;
  width: 0.5px;
  background-color: #cccccc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-pagination .ant-pagination-options::before,
.ant-pagination-total-text {
  color: #999999 !important;
}

.ant-pagination .ant-pagination-prev svg,
.ant-pagination .ant-pagination-next svg {
  fill: #999999 !important;
}

.ant-table-pagination .ant-pagination-options .ant-select-selection-item {
  color: var(--secondary-text) !important;
  font-weight: 600 !important;
}

.ant-pagination .ant-select .ant-select-arrow .anticon-down {
  display: none;
}

.ant-pagination .ant-select .ant-select-arrow {
  position: relative;
}

.ant-pagination .ant-select .ant-select-arrow::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid var(--secondary-text);
  right: 0;
  top: -100%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.ant-select:hover .ant-select-arrow:not(:last-child) {
  opacity: 1 !important;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(0) !important;
}

.nogridwidth>div {
  width: unset;
}

.ant-table-column-sorter-inner svg {
  fill: var(--hover-color);
}

.primecare .cursorPointer img {
  display: none;
}

.primecare .cursorPointer {
  position: relative;
  height: 40px;
  width: 40px;
}

.primecare .cursorPointer::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("./assets/Delete-icon-Primecare.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.primecare .hunset .cursorPointer {
  height: 30px;
  width: 30px;
}

.file-icon img {
  padding: 3px;
}

.ansSelected input {
  width: unset !important;
}

.ant-pagination-disabled {
  opacity: 0.5;
}
.fullwidth > div {
  width: 50%;
}
.fullwidth input{
  width: calc(100%);
}
.gap-20{
  gap: 20px;
}

.ant-radio-input,.ant-radio-inner{
  height: 20px !important;
  width: 20px !important;
}
.ant-radio-inner{
  border: 2px solid var(--btn-color) !important;
  background-color: white !important;
}
.ant-radio-checked .ant-radio-inner::after{
  background-color: var(--btn-color) !important;
  transform: scale(0.7) !important;
}
.primecare .ant-radio-checked .ant-radio-inner::after{
  background-color: var(--prime-active) !important;
  transform: scale(0.7) !important;
}
.flexWrapdpc{
  flex-wrap: wrap;
}