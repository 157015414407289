@font-face {
  font-family: "texgyrebonum";
  src: url("../src/font/texgyrebonum-bold.otf");
}

:root {
  --primecare-color: #15345a;
  --prime-btn-color: #cd6422;
  --prime-text-color: #303763;
  --prime-hover-color: #cd6422;
  --prime-sidebarcolor: #425a7e;
  --prime-bg-color: #f2f2f2;
  --prime-inactive: #1a1827;
  --prime-active: #cd6422;
  --prime-upload-bg: #cd642226;
  --prime-title-font: texgyrebonum;
  --prime-title-font-bold: texgyrebonum;
  --prime-label: white;
  --prime-color-15345a: white;
  --prime-999999: #999999;
}

.primecare .p-color {
  color: var(--primecare-color) !important;
}

.primecare .accordion-button {
  color: var(--prime-btn-color);
}

.primecare .optionselection input[type="radio"]+label>.checkradiobtn {
  border: 2px solid var(--prime-btn-color);
}

.primecare .optionselection input[type="checkbox"]+label>.checkradiobtn {
  border: 2px solid var(--prime-btn-color);
}

.primecare .primarybtn,
.primecare .primarybtn:hover {
  background-color: var(--prime-btn-color);
  color: var(--white);
}

.primecare .switches-container label {
  color: var(--prime-text-color);
}

.primecare .producttitle {
  color: var(--prime-text-color);
}

.primecare .btn-toggle>.handle {
  background: var(--prime-text-color);
}

.primecare .table .ant-table-column-title,
.primecare .table th {
  color: var(--prime-text-color);
}

.primecare .heedprodct {
  color: var(--prime-text-color);
  font-family: var(--prime-title-font-bold);
}

.primecare .sidebar-content label input:checked+li,
.primecare .sidebar-content label input:checked+li:hover {
  color: var(--prime-text-color);
  background-color: var(--white);
}

.primecare .login-form input:focus,
.primecare .login-form select:focus,
.primecare .login-form textarea:focus,
.primecare .ant-select-selector:focus {
  border-color: var(--prime-hover-color);
  color: var(--prime-hover-color);
}

.primecare .login-form select,
.primecare .login-form textarea {
  color: var(--prime-hover-color);
}

.primecare .login-form input:focus::placeholder {
  color: var(--prime-hover-color);
}

.primecare input:focus+.line::before {
  background-color: var(--prime-hover-color);
}
.primecare .ant-upload-drag:hover {
  background-color: var(--prime-upload-bg) !important;
}
.primecare .nav-link:focus,
.primecare .nav-link:hover,
.primecare .nav-link {
  color: var(--prime-hover-color) !important;
}

.primecare :where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
.primecare :where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: var(--prime-hover-color);
}

.primecare .editbuttom {
  color: var(--white);
  background-color: var(--prime-hover-color);
}

.primecare .ant-select-selection-item {
  /* color: var(--prime-hover-color); */
}
.primecare .ansSelected .ant-checkbox+span{
  background-color: var(--prime-upload-bg);
  border: 1px solid var(--prime-active);
}
.primecare .ant-select-item-option-content {
  color: var(--prime-hover-color);
}

.primecare .ant-select-open .ant-select-selector {
  border-color: var(--prime-hover-color) !important;
}

.primecare .ant-select-open .ant-select-arrow svg {
  fill: var(--prime-hover-color);
}

.primecare .cancelbtn,
.primecare .cancelbtn:hover,
.primecare .cancelbtn:active {
  font-size: 14px;
  color: var(--prime-hover-color) !important;
  border: 1px solid var(--prime-hover-color) !important;
  background-color: var(--white) !important;
}

.primecare .okbtn,
.primecare .okbtn:hover,
.primecare .okbtn:active {
  color: var(--white) !important;
  border: 1px solid var(--prime-hover-color) !important;
  background-color: var(--prime-hover-color) !important;
}

.primecare .addcreatebtn:hover {
  color: var(--white) !important;
  background-color: var(--prime-hover-color);
}
.primecare .addcreatebtn:hover img {
  filter: brightness(0) invert(1);
}

.primecare .optionselection input[type="checkbox"]:checked+label {
  border: 1px solid var(--prime-hover-color);
  color: var(--prime-hover-color);
}

.primecare .optionselection input[type="checkbox"]:checked+label>.checkradiobtn::after {
  background-color: var(--prime-hover-color);
}

.primecare .switch {
  background: var(--prime-hover-color);
}

.primecare .editfaq textarea:focus {
  color: var(--prime-hover-color);
}

.primecare .username {
  color: var(--prime-hover-color);
}

.primecare .noicon {
  color: var(--prime-hover-color);
}

.primecare .sidebar {
  background-color: var(--prime-sidebarcolor) !important;
}

.primecare .pro-sidebar+.rightside {
  background-color: var(--prime-bg-color);
}
.primecare .rightside{
  background-color: var(--prime-bg-color);
}
.primecare .rightcontent {
  background-color: var(--prime-bg-color);
}

.primecare .inactive {
  background-color: var(--prime-inactive);
  color: var(--white);
}

.primecare .userid {
  border: 1px solid var(--prime-inactive);
  color: var(--prime-inactive);
}

.primecare .userid:hover {
  color: var(--prime-inactive);
}

.primecare .detailtitle {
  color: var(--prime-inactive);
}

.primecare .notchange {
  color: var(--prime-inactive);
}

.primecare .sbg {
  background-color: var(--prime-active) !important;
}

.primecare .activted {
  background-color: var(--prime-active);
  color: var(--white);
}

.primecare .ant-switch.ant-switch-checked {
  background: var(--prime-active);
}

.primecare .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: var(--prime-active) !important  ;
}

.primecare .tabbing ul {
  border-bottom: 1px solid var(--prime-active);
}

.primecare .nav-tabs .nav-item.show .nav-link,
.primecare .nav-tabs .nav-link.active::after {
  background-color: var(--prime-active);
}

.primecare .form-check-input:focus {
  border-color: var(--prime-active);
}

.primecare .form-check-input:checked {
  background-color: var(--prime-active) !important;
  border-color: var(--prime-active) !important;
}

.primecare :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  background-color: var(--prime-active);
}

.primecare .addclaimbtn:hover {
  color: var(--white) !important;
  background-color: var(--prime-active);
}

.primecare .activetext {
  color: var(--prime-active);
}

.primecare .selectans input[type="checkbox"]:checked+label {
  border: 1px solid var(--prime-active);
  color: var(--secondary-text);
}

.primecare .optionselection input[type="radio"]:checked+label {
  border: 1px solid var(--prime-active);
  color: var(--secondary-text);
  /* position: relative; */
}

.primecare .headdetail {
  background-color: var(--prime-active);
  color: var(--prime-label);
}

.primecare .groupcheck input[type="checkbox"]:checked::before {
  background-color: var(--prime-active);
}

.primecare .ant-upload-list-item-container {
  background-color: var(--prime-upload-bg);
}

.primecare p>strong {
  font-family: var(--prime-title-font);
}





.primecare .btn-toggle:before {
  color: var(--prime-color-15345a);
}

.primecare .btn-toggle:after {
  color: var(--prime-color-15345a);
}

.primecare .logout,
.primecare .logout:hover {
  color: var(--prime-color-15345a);
}

.primecare .sidebar-content li {
  color: var(--prime-color-15345a);
}

.primecare .sidebar-content li.active {
  font-size: 18px;
  color: var(--text-color);
  opacity: 1;
  background-color: var(--white);
  border-radius: 4px;
  position: relative;
}

.primecare .btn-toggle:hover {
  background-color: var(--prime-color-15345a);
}

.primecare .btn-toggle {
  background: var(--prime-color-15345a);
}

.primecare .sidebar-content li.active::after {
  background-color: var(--white);
}

.primecare .ant-switch[aria-checked="false"] {
  background-color: var(--prime-999999);
}

.primecare .hoversection:hover {
  /* border: 1px solid var(--prime-active) !important; */
}

.primecare .liactivetext {
  color: var(--prime-active);
}

.primecare .pdfOnly {
  color: var(--prime-sidebarcolor);
}